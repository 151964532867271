import React from "react"
import { Router } from "@reach/router"

import Layout from "../other/layout"
import SEO from "../other/seo"

import { PREVIEW } from "../project-config"

const shop = () => {
  console.error("HEY THERE", PREVIEW)
  return (
    <Layout>
      <SEO title="Preview" />
      <Router></Router>
    </Layout>
  )
}

export default shop
